import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/cms-template.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Spacer = makeShortcode("Spacer");
const Testimonials = makeShortcode("Testimonials");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="bg-orange-200 flex flex-col lg:flex-row p-4 justify-between items-center">
  <div>
    <h3 className="mb-2">Explore career openings</h3>
    <p className="text-gray-600 my-2">View our open career opportunities on Glassdoor</p>
  </div>
  <div>
    <a className="btn m-2" href="https://www.glassdoor.com/Jobs/Foxtrot-Aviation-Services-Jobs-E2388196.htm?filter.countryId=1">View openings</a>
    <a className="btn btn-light" href="https://cdn.jotfor.ms/220144816610143">Apply online</a>
  </div>
    </div>
    <Spacer size="lg" mdxType="Spacer" />
    <h2>{`Growth Opportunities`}</h2>
    <p>{`FoxTrot Aviation Services is growing at 70% per year, which means we need your talent to succeed.  Far from a stale, stagnant workplace, FoxTrot offers the opportunity for real, dynamic growth in an expanding industry.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/growth-chart.png",
        "alt": "FoxTrot Career Growth Chart"
      }}></img></p>
    <Testimonials tag='careers' mdxType="Testimonials" />
    <Spacer size="lg" mdxType="Spacer" />
    <div className="text-center">
      <h2>{`See how work gets done here`}</h2>
    </div>
    <iframe className="m-auto" width="720" height="405" src="https://www.youtube.com/embed/tN72Eh4FIwc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <Spacer size="lg" mdxType="Spacer" />
    <div className="flex">
  <div className="w-3/5">
    <h3>Experienced aviation leadership that puts people and safety first.</h3>
    <p>With a combined 39 years of industry experience, FoxTrot's leadership team will prepare you for the next phase of your career journey.</p>
  </div>
  <div className="w-2/5 pl-6 pt-2">
    <ul className="font-bold">
      <li className="font-bold">Medical and Life Insurance</li>
      <li className="font-bold"> Dental & Vision Benefits</li>
      <li className="font-bold">Paid Time-off</li>
      <li className="font-bold">Company Matched 401k Plan</li>
    </ul>
  </div>
    </div>
    <Spacer mdxType="Spacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      